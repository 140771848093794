
/**
 * @name: index
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-1v1教练课程
 * @update: 2023-06-01 11:21
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {
  createCourseApi,
  getCourseDetailApi,
  modifyCourseApi,
  modifyCourseStatusApi,
  queryCourseListApi
} from "@/apis/coursesManage/courses";
import {CourseTypes, ICourse, ICourseQuery} from "@/apis/coursesManage/courses/types";
import {ICrudOption} from "@/types/m-ui-crud";
import router from "@/router";

@Component({})
export default class internetCelebrityPage extends Vue {

  queryForm: ICourseQuery = {
    courseTypes:CourseTypes.course1v1,
    page: 1,
    limit: 10
  }

  tableData:  ICourse[] = []
  total: number = 0

  // 新增修改表单
  modelForm: ICourse = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    // menu: false,
    editBtn:true,
    delBtn:false,
    column: [
      {
        label: "课程ID",
        prop: "id",
        align: 'center',
        width: 200,
        addHide:true,
        editHide:true,
      },
      {
        label: "主图",
        prop: "courseBannerPic",
        type: 'image',
        align: 'center',
        search: false,
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: '请上传轮播图片', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "课程名称",
        prop: "name",
        width: 200,
        align: 'center',
        search: true,
        addHide:true,
        editHide:true,
      },
      {
        label: "教练",
        prop: "coachUserName",
        align: 'center',
        search: true,
      },
      {
        label: "课程地区",
        prop: "courseLocation",
        align: 'center',
      },
      {
        label: "课程金额",
        prop: "price",
        align: 'center',
      },
      {
        label: "剩余卡次",
        prop: "courseCardCount",
        align: 'center',
      },
      {
        label: "核销码",
        prop: "ticketCodeNumber",
        align: 'center',
      },
      {
        label: "报名人数",
        prop: "enrollNumber",
        align: 'center',
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
        span: 12,
        rules: [{
          required: true,
          validator: (rule: any, value: any, callback: any) => {
            if (!value) {
              return callback(new Error("请输入排序"));
            } else if (!/^[1-9]\d{0,4}$/.test(value)) {
              callback(new Error("请输入排序为正整数，最大长度4位数!"));
            } else {
              callback();
            }
          }, trigger: 'blur'
        },
        ],
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        width: 150,
        search: true,
        slot: true,
        addHide:true,
        editHide:true,
        value: 2,
        type: "switch",
        dicData: [{label: '下架', value: 2}, {label: '上架', value: 1}],
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    queryCourseListApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    modifyCourseStatusApi({id: row.id, status: val,courseTypes:3}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }


  /**
   * 新增提交
   * @param  form: any, done: any, loading: any
   */
  submitSave(form: any, done: any, loading: any) {
    createCourseApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   * @param  form: any, done: any, loading: any
   */
  submitEdit(form: any, done: any, loading: any) {
    modifyCourseApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改前,添加前
   * @param  item: any
   */
  handleEditOrAdd(item?: any) {
    let url='/coursesManage/coach/add'
    if(item)
      url=url+`?id=${item.id}`
    router.push({path:url})
  }


  created() {
    this.getList();
  }
}
